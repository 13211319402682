import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './labels.scss';

export const PromoLabel: React.FC<{
  promo: string;
}> = (props) => {
  const { t } = useTranslation('common');
  return <Box className="label-box">{t(`search.filters.promo.options.${props.promo}`, props.promo)}</Box>;
};
